import styled from 'styled-components'

export const Details = styled.section`
  max-width: 108rem;
  margin: 3rem auto 0;
  padding: 0 3.7rem;
  color: ${({ theme }) => theme.darkgray};

  p {
    margin-top: 2rem;
    padding-left: 3rem;
  }

  ${({ theme }) => theme.mq.tablet} {
    margin: 3rem auto 4.2rem;
  }
`
export const Number = styled.span`
  color: ${({ theme }) => theme.lightgray};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.body.xxl};
`

export const Content = styled.div``

export const Header = styled.h2`
  position: relative;
  padding-left: 0.5rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  ::after {
    position: absolute;
    top: 3.8rem;
    left: 0;
    width: 0.5rem;
    height: 10rem;
    background: ${({ theme }) => theme.red};
    content: '';
  }
`
