import React from 'react'

import * as S from './equipment.styles'

interface EquipmentProps {
  carEquipmentCol1: string[]
  carEquipmentCol2: string[]
  carEquipmentCol3: string[]
}

const Equipment: React.FC<EquipmentProps> = ({ carEquipmentCol1, carEquipmentCol2, carEquipmentCol3 }) => {
  const boxes = [carEquipmentCol1, carEquipmentCol2, carEquipmentCol3]

  return (
    <S.Equipment>
      <S.Number>01</S.Number>
      <S.Content>
        <S.Header>Wyposażenie</S.Header>
        <S.BoxesContainer>
          {boxes.map((box, i) => (
            <S.Box key={`${box[0]}-${i}`}>
              {box.map(item => (
                <p key={item}>{item}</p>
              ))}
            </S.Box>
          ))}
        </S.BoxesContainer>
      </S.Content>
    </S.Equipment>
  )
}

export default Equipment
