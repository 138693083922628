import styled from 'styled-components'
import Slider from 'react-slick'
import Image, { FluidObject } from 'gatsby-image'

export const Container = styled(Slider)`
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.mq.tablet} {
    flex-basis: 65%;
  }

  ${({ theme }) => theme.mq.bigDesktop} {
    flex-basis: 70%;
    max-height: 100rem;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: unset;
    bottom: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndex.level1};
    width: 5rem;
    height: 5rem;
    background: ${({ theme }) => theme.white};
    transform: unset;

    ${({ theme }) => theme.mq.tablet} {
      width: 7rem;
      height: 7rem;
    }

    ::before {
      color: ${({ theme }) => theme.red};
      opacity: 1;

      ${({ theme }) => theme.mq.tablet} {
        font-size: 3rem;
      }
    }

    :hover,
    :focus {
      background: ${({ theme }) => theme.white};
    }
  }

  .slick-next {
    left: 5rem;

    ${({ theme }) => theme.mq.tablet} {
      left: 7rem;
    }
  }
`

export const Slide = styled(Image)<{ fluid: FluidObject }>`
  ${({ theme }) => theme.mq.tablet} {
    min-height: calc(100vh - 8rem);
    max-height: 100rem;
  }
`
