import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'src/components/layout/layout'
import Seo from 'src/components/seo'
import Hero from 'src/components/singleCarSections/hero'
import Equipment from 'src/components/singleCarSections/equipment'
import Details from 'src/components/singleCarSections/details'
import Help from 'src/components/singleCarSections/help'

import { SingleCarProps } from './types/types'
import { extractEquipmentData } from './utlis/extractEquipmentData'

const SingleCar: React.FC<SingleCarProps> = ({ data, location }) => {
  const {
    carName,
    seoTitle,
    seoDescription,
    carEngineType,
    carYear,
    carMileage,
    carEngineCapacity,
    carPrice,
    carEquipmentCol1,
    carEquipmentCol2,
    carEquipmentCol3,
    carDescription,
    carCarousel,
  } = data.contentfulCar

  return (
    <Layout>
      <Seo title={seoTitle} description={seoDescription} />
      <Hero
        carName={carName}
        carYear={carYear}
        carMileage={carMileage}
        carEngineCapacity={carEngineCapacity}
        carEngineType={carEngineType}
        carPrice={carPrice}
        carCarousel={carCarousel}
      />
      <Equipment
        carEquipmentCol1={extractEquipmentData(carEquipmentCol1.raw)}
        carEquipmentCol2={extractEquipmentData(carEquipmentCol2.raw)}
        carEquipmentCol3={extractEquipmentData(carEquipmentCol3.raw)}
      />
      <Details carDescription={extractEquipmentData(carDescription.raw)} />
      <Help carName={carName} carLink={location.href} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulCar(slug: { eq: $slug }) {
      carName
      seoTitle
      seoDescription
      carEngineType
      carYear
      carMileage
      carEngineCapacity
      carPrice
      carDescription {
        raw
      }
      carEquipmentCol1 {
        raw
      }
      carEquipmentCol2 {
        raw
      }
      carEquipmentCol3 {
        raw
      }
      carCarousel {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

export default SingleCar
