import React from 'react'

import * as S from './details.styles'

interface PropsDetails {
  carDescription: string[]
}

const Details: React.FC<PropsDetails> = ({ carDescription }) => (
  <S.Details>
    <S.Number>02</S.Number>
    <S.Content>
      <S.Header>Opis</S.Header>
      {carDescription.map(item => (
        <p key={item}>{item}</p>
      ))}
    </S.Content>
  </S.Details>
)

export default Details
