import React from 'react'
import { FluidObject } from 'gatsby-image'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as S from './carousel.styles'

interface CarouselProps {
  carCarousel: [{ fluid: FluidObject }]
}

const Carousel: React.FC<CarouselProps> = ({ carCarousel }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
  }

  return (
    <S.Container {...settings}>
      {carCarousel.map(image => (
        <S.Slide key={image.fluid.src} fluid={image.fluid} />
      ))}
    </S.Container>
  )
}

export default Carousel
