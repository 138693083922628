import styled from 'styled-components'

export const Help = styled.section`
  max-width: 108rem;
  margin: 0 auto;
  padding: 0 3.7rem;
`

export const Header = styled.h2`
  position: relative;
  padding-left: 0.5rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  ::after {
    position: absolute;
    top: 3.8rem;
    left: 0;
    width: 0.5rem;
    height: 10rem;
    background: ${({ theme }) => theme.red};
    content: '';
  }
`

export const Number = styled.span`
  color: ${({ theme }) => theme.lightgray};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.body.xxl};
`
export const Content = styled.div``

export const Paragraph = styled.p`
  margin: 2rem 0 3rem 3rem;
  line-height: 1.9;
  span {
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }
`
