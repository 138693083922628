import React from 'react'
import Form from 'src/components/form/form'

import * as S from './help.styles'

interface HelpProps {
  carName: string
  carLink: string
}

const Help: React.FC<HelpProps> = ({ carName, carLink }) => (
  <S.Help>
    <S.Number>03</S.Number>
    <S.Content>
      <S.Header>Pomoc</S.Header>
      <S.Paragraph>
        <span>Potrzebujesz naszej pomocy albo masz pytanie? Zadaj nam je. Służymy doświadczeniem.</span>
        <br />
        Pisząc wiadomość, upewnij się, że podajesz poprawnego maila.
      </S.Paragraph>
    </S.Content>
    <Form carName={carName} carLink={carLink} />
  </S.Help>
)

export default Help
