import React from 'react'
import { FluidObject } from 'gatsby-image'

import * as S from './hero.styles'

import Carousel from 'src/components/carousel/carousel'

interface HeroProps {
  carName: string
  carYear: string
  carMileage: string
  carEngineCapacity: string
  carEngineType: string
  carPrice: string
  carCarousel: [{ fluid: FluidObject }]
}

const Hero: React.FC<HeroProps> = props => {
  const { carName, carYear, carMileage, carEngineCapacity, carEngineType, carPrice, carCarousel } = props
  return (
    <S.Hero>
      <S.Content>
        <S.Details>
          <S.Header>{carName}</S.Header>
          <S.Paragraph>
            <span>{carYear}</span>
          </S.Paragraph>
          <S.Paragraph>
            <span>{carMileage}</span>
          </S.Paragraph>
          <S.Paragraph>
            <span>{carEngineCapacity}</span>
          </S.Paragraph>
          <S.Paragraph>
            <span>{carEngineType}</span>
          </S.Paragraph>
        </S.Details>
        <Carousel carCarousel={carCarousel} />
      </S.Content>
      <S.PriceWrapper>
        <S.Price>{carPrice}</S.Price>
      </S.PriceWrapper>
    </S.Hero>
  )
}

export default Hero
