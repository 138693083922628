import styled from 'styled-components'

export const Hero = styled.section`
  padding-top: 5rem;
  color: ${({ theme }) => theme.darkgray};

  ${({ theme }) => theme.mq.tablet} {
    padding-top: 8rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;
  }
`

export const Details = styled.div`
  margin-top: 3rem;
  padding: 0 3.7rem;

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
  }

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.headers.xxl};
  }
`

export const Header = styled.h1`
  position: relative;
  max-width: 25rem;
  margin-bottom: 5.6rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  ::after {
    position: absolute;
    bottom: -2rem;
    left: 0;
    width: 14rem;
    height: 1.6rem;
    background: ${({ theme }) => theme.red};
    content: '';
  }

  ${({ theme }) => theme.mq.bigTablet} {
    font-size: ${({ theme }) => theme.font.size.headers.xxl};
  }
`

export const Paragraph = styled.p`
  position: relative;
  margin-bottom: 2rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  span {
    position: relative;
    display: block;
  }

  ::before {
    position: absolute;
    top: 60%;
    left: 0.5rem;
    width: 9rem;
    height: 1.6rem;
    background: ${({ theme }) => theme.lightgray};
    content: '';
  }

  ${({ theme }) => theme.mq.bigTablet} {
    margin-bottom: 5.8rem;
  }

  :last-of-type {
    margin-bottom: 0;
  }
`

export const PriceWrapper = styled.div`
  max-width: 108rem;
  margin: 0 auto;
  padding: 0 3.7rem;
  text-align: right;

  ${({ theme }) => theme.mq.tablet} {
    margin-top: 4rem;
  }
`

export const Price = styled.h2`
  position: relative;
  color: ${({ theme }) => theme.red};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  ::after {
    position: absolute;
    right: 0;
    bottom: -2rem;
    width: 80%;
    max-width: 27rem;
    height: 1.6rem;
    background: ${({ theme }) => theme.lightgray};
    content: '';
  }

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.headers.xl};
  }

  ${({ theme }) => theme.mq.bigTablet} {
    font-size: ${({ theme }) => theme.font.size.headers.xxl};
  }
`
